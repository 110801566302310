import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SectionOne from '../pagePartials/business/SectionOne';
import SectionTwo from '../pagePartials/business/SectionTwo';
import SectionThree from '../pagePartials/business/SectionThree';
import SectionFour from '../pagePartials/business/SectionFour';
import SectionFive from '../pagePartials/business/SectionFive';
import SectionSix from '../pagePartials/business/SectionSix';
import SectionSeven from '../pagePartials/business/SectionSeven';
import SectionEight from '../pagePartials/business/SectionEight';

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personID: 0,
    };
  }

  componentDidMount() {
    window.location.href = 'http://business.inkblottherapy.com/';
  }

  recievePerson = personID => {
    this.setState({ personID });
  };

  render() {
    return (
      <Layout location={this.props.location}>
        <div className="business-page">
          <SectionOne />
          <SectionTwo currentPerson={this.state.personID} recievePerson={this.recievePerson} />
          <SectionThree currentPerson={this.state.personID} recievePerson={this.recievePerson} />
          <SectionFour currentPerson={this.state.personID} recievePerson={this.recievePerson} />
          <SectionFive currentPerson={this.state.personID} recievePerson={this.recievePerson} />
          <div style={{ height: '70px', backgroundColor: '#2e5fca', border: 'none' }} />
          <SectionSix />
          <div style={{ height: '70px', backgroundColor: '#2e5fca', border: 'none' }} />
          <SectionEight />
          <SectionSeven />
        </div>
      </Layout>
    );
  }
}

Business.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Business;
